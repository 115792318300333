<template>
	<div v-if="authed">
        <v-data-table :headers="headers" :items="items" :loading="loading" loading-text="加载中..." :options="{itemsPerPage:20}">
            <template v-slot:item.actions="{ item }">
                <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            </template>
			<template v-slot:item.customerTemplate.template="{ item }">
				{{getTemplateStr(item)}}
			</template>
		</v-data-table>
		<v-dialog v-model="dialog" persistent max-width="360">
			<v-card>
				<v-card-title>修改</v-card-title>
				<v-card-text>
					<v-form ref="settingform">
						<v-text-field label="标题" :rules="textRules" v-model="cur.title"/>
						<v-text-field label="任务提醒" :rules="textRules" v-model="cur.prompt"/>
						<v-row>
							<v-col md="6">
								<v-text-field label="提醒阈值(小时)" :rules="numberRules" v-model="cur.remindOffset"/>
							</v-col>
							<v-col md="6">
								<v-text-field label="超时阈值(小时)" :rules="numberRules"  v-model="cur.expiredOffset"/>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn @click="dialog=false">取消</v-btn>
					<v-btn color="primary" @click="save" :loading="loadingnew" :disabled="loadingnew">保存</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
    export default {
        name: 'Setting',
        data() {
            return {
                authed: false,
				headers: [
                    {text:'id', value:'_id', width:150, sortable: false},
                    {text:'标题', value:'title', width:160, sortable: false},
                    {text:'任务提醒', value:'prompt', width:240, sortable: false},
                    {text:'提醒阈值(小时)', value:'remindOffset', width:150, sortable: false},
                    {text:'超时阈值(小时)', value:'expiredOffset', width:150, sortable: false},
                    {text:'客户消息标题', value:'customerTemplate.title', width:120, sortable: false},
                    {text:'客户消息模板', value:'customerTemplate.template', width:480, sortable: false},
                    {text:'修改', value:'actions', width:100, sortable: false}
				],
				items: [],
				loading: false,
				dialog: false,
				loadingnew: false,
				textRules: [v => !!v || '此项为必填'],
				numberRules: [v => !v || (/^-?[0-9]+(.[0-9]*)?$/g).test(v) || '只允许数字'],
				cur: {}
            }
        },
        mounted() {
			this.authed = this.$hasPrivilege('修改设置');
			if (!this.authed) return;
            this.fetchData();
        },
        methods: {
            fetchData() {
				this.loading = true;
                const db = this.$tcbapp.database();
                db.collection('setting').get().then(res => {
                    this.items = res.data;
                }).finally(() => {
					this.loading = false;
				})
			},
			getTemplateStr(item) {
				if (!item.customerTemplate) return "";
				const tm = item.customerTemplate.template;
				if (Object.prototype.toString.call(tm) === '[object Object]') {
					return JSON.stringify(tm);
				}
				return tm;
			},
			editItem(item) {
				this.cur = {...item};
				this.$nextTick(() => {
					this.$refs.settingform.resetValidation();
				});
				this.dialog = true;
			},
			save() {
				if (!this.$refs.settingform.validate()) return;
				let {title, prompt, remindOffset, expiredOffset} = this.cur;
				const obj = {
					title,
					prompt,
					remindOffset: remindOffset && parseFloat(remindOffset),
					expiredOffset: expiredOffset && parseFloat(expiredOffset)
				};
				this.loadingnew = true;
                const db = this.$tcbapp.database();
                db.collection('setting').doc(this.cur._id).update(obj).then(res=>{
					this.fetchData();
					this.dialog = false;
				})
				.catch(console.error)
				.finally(() => {
					this.loadingnew = false;
				})
			}
		}
	}
</script>
